import React from "react";
import "./App.css";

const App = () => {
  return (
    <>
      <div className="wrapper">
        <div className="wrapper-text">
          <h1>We work in collaboration with Dimensione Creativa</h1>
          <div className="wrapper-text-hyperlink">
            <h2>Visit</h2>
            <a href="https://www.dchouse.pl">Dimensione Creativa</a>
            <h2>for the details</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
